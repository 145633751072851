<template>
  <v-row>
    <v-col cols="12" lg="3">
      <base-card>
        <v-card-text>
          <div class="flex items-center justify-center mb-6">
            <div class="text-center">
              <h5 class="font-bold my-3">{{ productDetails.name }}</h5>
            </div>
          </div>
          <v-divider class="my-4" />
          <div class="flex items-end justify-between mb-4">
            <p class="mt-2 mb-0 flex-1">Price</p>
            <div class="flex-1">
              <p class="text-right text-xs m-0">{{ productDetails.price }}</p>
            </div>
          </div>
          <div class="flex items-end justify-between mb-4">
            <p class="mt-2 mb-0 flex-1">Code</p>
            <div class="flex-1">
              <p class="text-right text-xs m-0">{{ productDetails.code }}</p>
            </div>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
    <v-col cols="12" lg="9">
      <div class="flex items-center">
        <p class="font-semibold mr-3 mb-3">Product Stock</p>
        <v-divider />
      </div>

      <base-card>
        <v-card-title>
          <div class="flex justify-between flex-wrap">
            <v-dialog v-model="dialog" max-width="600px">
              <template v-if="addStock" v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-1 button"
                  dark
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                  @click="reset"
                >
                  <v-icon>mdi-plus</v-icon>
                  Add Stock
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline" v-if="isEditing">Edit Stock</span>
                  <span class="headline" v-else>Add Stock</span>
                </v-card-title>

                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <label for="">Product Name</label>
                          <v-text-field
                            filled
                            color="info"
                            v-model="productDetails.name"
                            readonly
                            :rules="emptyRules"
                          ></v-text-field>
                          <div v-if="!isEditing">
                            <label for="">Store</label>
                            <v-select
                              v-model="storeId"
                              :items="stores"
                              item-value="id"
                              item-text="name"
                              filled
                              label="Select Store"
                            />
                          </div>
                          <label for="">Quantity</label>
                          <v-text-field
                            filled
                            color="info"
                            v-model="quantity"
                            type="number"
                            :rules="emptyRules"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-container>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="m-2" color="error" @click="closeModal"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-if="!isEditing"
                        class="m-2"
                        color="info"
                        @click="saveStock"
                        :loading="loading"
                        >Save Stock</v-btn
                      >
                      <v-btn
                        v-else
                        class="m-2"
                        color="info"
                        @click="updateStock"
                        :loading="loading"
                        >Update Stock</v-btn
                      >
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-card>
            </v-dialog>

            <div></div>
          </div>
        </v-card-title>
        <mayventory-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="items"
          title="Product Stock"
          item-key="name"
          class="elevation-1 table-one"
          multi-sort
        >
        </mayventory-table>
      </base-card>
    </v-col>
    <div class="text-center">
      <v-dialog v-model="viewDialog" width="500">
        <base-card>
          <v-card-title>
            <span class="headline">Edit Quantity</span>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <label for="">Quantity</label>
                    <v-text-field
                      filled
                      color="info"
                      v-model="quantity"
                      type="number"
                      :rules="emptyRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="m-2" color="error" @click="closeModal"
                  >Cancel</v-btn
                >
                <v-btn
                  class="m-2"
                  color="info"
                  :loading="loading"
                  @click="updateQuantity"
                  >Update Quantity</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-form>
        </base-card>
      </v-dialog>
    </div>
  </v-row>
</template>
<script>
import {
  GET_PRODUCT_STOCK_LIST,
  ADD_STOCK,
  VIEW_SINGLE_PRODUCT,
  UPDATE_STOCK,
  DELETE_STOCK,
  UPDATE_STOCK_QUANTITY,
  GET_USER_STORES,
  COMPANY_STORE,
} from "../../../constants/graphql";
import Swal from "sweetalert2";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Profile 2",
  },
  data() {
    return {
      isEditing: false,
      viewDialog: false,
      search: "",
      selected: [],
      userPermission: this.$store.state.authData.userPermissions,
      headers: [
        {
          text: "S/N",
          value: "sno",
          width: "12%",
        },
        {
          text: "Product",
          sortable: false,
          value: "product.name",
        },
        { text: "Quantity", value: "quantity" },
        { text: "Store", value: "store.name" },
        { text: "Action", value: "action" },
      ],
      items: [],
      productDetails: "",
      stockId: "",
      storeId: "",
      quantity: "",
      dialog: false,
      loading: false,
      productId: null,
      emptyRules: [(v) => !!v || "Field is required"],
      valid: true,
      productId: parseInt(this.$route.params.id),
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    addStock() {
      if (this.userPermission.includes("add_stock")) {
        return true;
      }
    },
  },
  apollo: {
    stores: {
      query: COMPANY_STORE,
      update: (result) => result.getCompanyStoreList,
      error(error) {
        handleError(error);
      },
    },
    items: {
      query: GET_PRODUCT_STOCK_LIST,
      variables() {
        return {
          productId: this.productId,
        };
      },
      update: (result) => result.getProductStockList,
    },
    productDetails: {
      query: VIEW_SINGLE_PRODUCT,
      variables() {
        return {
          productId: this.productId,
        };
      },
      update: (result) => result.getSingleProductDetails,
    },
  },
  created() {
    this.$root.$refs.VP = this;
  },
  methods: {
    close() {
      this.dialog = false;
      this.isEditing = false;
    },

    saveStock() {
      let validated = this.$refs.form.validate();
      if (validated === true) {
        this.loading = true;
        this.productId = parseInt(this.$route.params.id);
        this.$apollo
          .mutate({
            mutation: ADD_STOCK,
            variables: {
              productId: this.productId,
              storeId: this.storeId,
              quantity: parseInt(this.quantity),
            },
          })
          .then((result) => {
            let resp = result.data.createNewStock;
            let dialogText = `
        <p>${resp.product.name} stock has been added to MayVentory successfully.</p>
        <p>Product Stock details are:</p>
        <p><strong>Quantity:</strong> ${resp.quantity} </p>
        `;
            Swal.fire({
              html: `<div>${dialogText}</div>`,
              icon: "success",
              customClass: {
                popup: "format-pre",
              },
            });
            this.$apollo.queries.items.refetch();
          });
        this.closeModal();
      }
    },

    closeModal() {
      this.dialog = false;
      this.viewDialog = false;
      this.isEditing = false;
      this.loading = false;
    },

    reset() {
      this.storeId = "";
      this.quantity = "";

      this.isEditing = false;
      this.dialog = true;
    },

    edit(item) {
      this.storeId = item.storeId;
      this.quantity = item.quantity;
      this.stockId = item.id;

      this.isEditing = true;
      this.dialog = true;

      //this.$apollo.queries.items.refetch();
    },
    vDialog(item) {
      this.viewDialog = true;
      this.stockId = item.id;

      this.quantity = "";
      //this.$apollo.queries.items.refetch();
    },

    updateQuantity() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_STOCK_QUANTITY,
          variables: {
            stockId: this.stockId,
            quantity: parseInt(this.quantity),
          },
        })
        .then((_) => {
          this.$apollo.queries.items.refetch();
          Swal.fire({
            text: "Stock Quantity Update successful",
            icon: "success",
          });
          this.$apollo.queries.items.refetch();
        });
      this.closeModal();
    },

    updateStock() {
      this.loading = true;
      this.productId = parseInt(this.$route.params.id);
      this.$apollo
        .mutate({
          mutation: UPDATE_STOCK,
          variables: {
            stockId: this.stockId,
            storeId: this.storeId,
            productId: this.productId,
            quantity: parseInt(this.quantity),
          },
        })
        .then((_) => {
          Swal.fire({
            text: "Stock Update successful",
            icon: "success",
          });
          this.$apollo.queries.items.refetch();
        });
      this.closeModal();
    },

    deleteStock(StockId) {
      Swal.fire({
        text: `Do you wish to delete this Stock?`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Yes",
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_STOCK,
              variables: {
                stockId: StockId,
              },
            })
            .then((_) => {
              Swal.fire({
                toast: true,
                text: `Stock Deleted from MayVentory`,
                icon: "success",
                timer: 3000,
                timerProgressBar: true,
                position: "top-end",
              });
              this.$apollo.queries.items.refetch();
            });
        }
      });
      this.closeModal();
    },
  },
};
</script>
